<template>
  <div>
    <!-- ----------------------------------------------- -->
    <!-- top cards -->
    <!-- ----------------------------------------------- -->
    <b-row>
      <b-col cols="12" md="6" lg="3">
        <b-card class="mb-4">
          <h4 class="card-title mb-1">{{ airTemperature }} °C</h4>
          <b-card-text class="mb-2">Temperatura dell'aria</b-card-text>
          <b-progress value="100" max="100"></b-progress>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-card class="mb-4">
          <h4 class="card-title mb-1">{{ windSpeedU }} m/s</h4>
          <b-card-text class="mb-2">Velocità del vento (sud -> nord)</b-card-text>
          <b-progress variant="danger" value="100" max="100"></b-progress>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-card class="mb-4">
          <h4 class="card-title mb-1">{{ windSpeedV }} m/s</h4>
          <b-card-text class="mb-2">Velocità del vento (ovest -> est)</b-card-text>
          <b-progress variant="success" value="100" max="100"></b-progress>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-card class="mb-4">
          <h4 class="card-title mb-1">{{ convectiveEnergy }} J*kg-1</h4>
          <b-card-text class="mb-2">Energia potenziale disponibile convettiva</b-card-text>
          <b-progress variant="warning" value="100" max="100"></b-progress>
        </b-card>
      </b-col>
    </b-row>

    <!-- <b-row>
      <b-col cols="12" lg="12">
        <b-card class="mb-4 rischio">
          <h4 class="card-title">Indici di rischio</h4>
          <br><br>
          <b-row style="justify-content: space-evenly;">
            <b-col class="basis-0">
              <PercentageCircle :percent="firstRisk" :active-color="getColor(firstRisk)" size="big" :animate="true" complete-color="green" class="percentage" />
              <div style="text-align: center;margin-top: 10px;">Incendi</div>
            </b-col>
            <b-col class="basis-0">
              <PercentageCircle :percent="secondRisk" :active-color="getColor(secondRisk)" size="big" :animate="true" complete-color="green" class="percentage" />
              <div style="text-align: center;margin-top: 10px;">Frane</div>
            </b-col>
            <b-col class="basis-0">
              <PercentageCircle :percent="thirdRisk" :active-color="getColor(thirdRisk)" size="big" :animate="true" complete-color="green" class="percentage" />
              <div style="text-align: center;margin-top: 10px;">Alluvioni</div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row> -->
    <!-- ----------------------------------------------- -->
    <!-- end top cards -->
    <!-- ----------------------------------------------- -->
    <b-card class="mb-4">
      <LineChart :dataset="potentialEnergy" color="#2962ff" :xAxisLabel="xAsisLabels" unit="kWh"  :key="potentialEnergy.data"/>
    </b-card>
    <b-row>
      <b-col cols="12" lg="6">
        <b-card class="mb-4">
          <h4 class="card-title"></h4>
          <!-- <SalesSummary /> -->
          <!-- <b-form-datepicker id="example-datepicker" @input="changeFloodDate" v-model="floodDate" class="mb-2"></b-form-datepicker> -->
          <div class="map-frame">
            <div id="flood"></div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" lg="6">
        <b-card class="mb-4" id="append">
          <!-- <h4 class="card-title">Indici di rischio</h4> -->
          <!-- <br><br> -->
          <!-- <b-row style="justify-content: space-evenly;">
            <b-col class="basis-0">
              <PercentageCircle :percent="firstRisk" :active-color="getColor(firstRisk)" size="big" :animate="true" complete-color="green" class="percentage" />
              <div style="text-align: center;margin-top: 10px;">Incendi</div>
            </b-col>
            <b-col class="basis-0">
              <PercentageCircle :percent="secondRisk" :active-color="getColor(secondRisk)" size="big" :animate="true" complete-color="green" class="percentage" />
              <div style="text-align: center;margin-top: 10px;">Frane</div>
            </b-col>
            <b-col class="basis-0">
              <PercentageCircle :percent="thirdRisk" :active-color="getColor(thirdRisk)" size="big" :animate="true" complete-color="green" class="percentage" />
              <div style="text-align: center;margin-top: 10px;">Alluvioni</div>
            </b-col>
          </b-row> -->
          <!-- <div class="map-frame"> -->
            <!-- <div id="windy"></div> -->
            <!-- <LineChart :dataset="potentialEnergy" color="#2962ff" :xAxisLabel="xAsisLabels" unit="kWh"  :key="potentialEnergy.data"/> -->
            <div id="windy"></div>
          <!-- </div> -->
        </b-card>
      </b-col>
    </b-row>
    <!-- ----------------------------------------------- -->
    <!-- top selling products -->
    <!-- ----------------------------------------------- -->
    <!-- <b-card class="mb-4" no-body>
      <b-card-body>
        <h4 class="card-title">Top Selling Products</h4>
        <h6 class="card-subtitle font-weight-normal text-muted">
          Overview of Latest Month
        </h6>
      </b-card-body>
      <SellingProduct />
    </b-card> -->
    <!-- ----------------------------------------------- -->
    <!-- end top selling products -->
    <!-- ----------------------------------------------- -->
    <!-- ----------------------------------------------- -->
    <!-- cards row -->
    <!-- ----------------------------------------------- -->
    <!-- <TopCards /> -->
    <!-- ----------------------------------------------- -->
    <!-- end cards row -->
    <!-- ----------------------------------------------- -->
    <b-row>
      <b-col cols="12" md="6" lg="3">
        <b-card class="mb-4">
          <h4 class="card-title mb-1">{{ windGust }} m/s</h4>
          <b-card-text class="mb-2">Velocità del vento a raffiche</b-card-text>
          <b-progress value="100" max="100"></b-progress>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-card class="mb-4">
          <h4 class="card-title mb-1">{{ pressure }} Pa</h4>
          <b-card-text class="mb-2">Pressione dell'aria</b-card-text>
          <b-progress variant="danger" value="100" max="100"></b-progress>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-card class="mb-4">
          <h4 class="card-title mb-1">{{ ptype }}</h4>
          <b-card-text class="mb-2">Tipo precipitazione</b-card-text>
          <b-progress variant="success" value="100" max="100"></b-progress>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-card class="mb-4">
          <h4 class="card-title mb-1">{{ dewPoint }} °C</h4>
          <b-card-text class="mb-2">Punto di rugiada</b-card-text>
          <b-progress variant="warning" value="100" max="100"></b-progress>
        </b-card>
      </b-col>
    </b-row>
    <br>

<h4 class="card-title" style="font-size: 30px;text-align: center;">Previsione a 3 giorni</h4>

    <b-row>
      <b-col  cols="12" md="4" lg="4" v-if="windGustDataset.data != null && xAsisLabels != null">
        <LineChart :dataset="windGustDataset" color="#2962ff" :xAxisLabel="xAsisLabels" unit="m/s"  :key="windGustDataset.data"/>
      </b-col>
      <b-col  cols="12" md="4" lg="4" v-if="winduDataset.data != null && xAsisLabels != null">
        <LineChart :dataset="winduDataset" color="#0bb7af" :xAxisLabel="xAsisLabels" unit="m/s" :key="winduDataset.data"/>
      </b-col>
      <b-col  cols="12" md="4" lg="4" v-if="windvDataset.data != null && xAsisLabels != null">
        <LineChart :dataset="windvDataset" color="#fbcd00" :xAxisLabel="xAsisLabels" unit="m/s" :key="windvDataset.data"/>
      </b-col>
    </b-row>
    <br><br><br>
    <b-row>
      <b-col  cols="12" md="4" lg="4" v-if="tempDataset.data != null && xAsisLabels != null">
        <!-- <LineChart :dataset="tempDataset" color="#2962ff" :xAxisLabel="xAsisLabels" unit="°C" :key="tempDataset.data"/> -->
        <BarChart :dataset="tempDataset" color="#2962ff" :xAxisLabel="xAsisLabels" unit="°C" :key="tempDataset.data" />
      </b-col>
      <b-col  cols="12" md="4" lg="4" v-if="airPressureDataset.data != null && xAsisLabels != null">
        <!-- <LineChart :dataset="airPressureDataset" color="#0bb7af" :xAxisLabel="xAsisLabels" unit="Pa" :key="airPressureDataset.data"/> -->
        <BarChart :dataset="airPressureDataset" color="#0bb7af" :xAxisLabel="xAsisLabels" unit="Pa" :key="airPressureDataset.data" />
      </b-col>
      <b-col  cols="12" md="4" lg="4" v-if="dewPointDataset.data != null && xAsisLabels != null">
        <!-- <LineChart :dataset="dewPointDataset" color="#fbcd00" :xAxisLabel="xAsisLabels" unit="°C" :key="dewPointDataset.data" /> -->
        <BarChart :dataset="dewPointDataset" color="#fbcd00" :xAxisLabel="xAsisLabels" unit="°C" :key="dewPointDataset.data" />
      </b-col>
    </b-row>

  </div>
</template>

<script>
import LineChart from '../dashboard-components/LineChart'
import BarChart from '../dashboard-components/BarChart.vue';
import axios from 'axios'
import "leaflet/dist/leaflet.css"
export default {
  name: "Starter",
  data: () => ({
    floodDate: '2023-05-23',
    firstRisk: 50,
    secondRisk: 30,
    thirdRisk: 90,
    precip: 0,
    windSpeedU: 0,
    windSpeedV: 0,
    umidity: 0,
    airTemperature: 0,
    pressure: 0,
    ptype: '',
    dewPoint: 0,
    gradient: null,
    xAsisLabels: null,
    precipDataset: { 
      label: 'Precipitazioni',
      data: null
    },
    windGustDataset: { 
      label: 'Velocità del vento a raffiche',
      data: null
    },
    potentialEnergy: {
      label: 'Energia Potenziale',
      data: null
    },
    winduDataset:{
      label: 'Velocità del vento da Sud a Nord',
      data: null
    },
    windvDataset:{
      label: 'Velocità del vento da Ovest a Est',
      data: null
    },
    tempDataset:{
      label: "Temperatura dell'aria",
      data: null
    },
    airPressureDataset:{
      label: "Pressione dell'aria",
      data: null
    },
    dewPointDataset:{
      label: "Punto di rugiada",
      data: null
    },
    map: null,
    wmsLayer: null,
    wmsLayer2: null,
    lat: '',
    long: '',
    windGust: 0,
    convectiveEnergy: 0
  }),
  mounted(){

    const defaultIcon = new window.L.icon({
      iconUrl: require('../../../node_modules/leaflet/dist/images/marker-icon.png'),
      iconSize:     [25, 41],
        shadowSize:   [30, 65],
        iconAnchor:   [12, 41],
        shadowAnchor: [7, 65]
    });

    this.lat = this.$route.params.lat ? this.$route.params.lat : 44.566755;
    this.long = this.$route.params.long ? this.$route.params.long : 11.756816;

    const lat = this.$route.params.lat;
    const long = this.$route.params.long;
    const date = this.$route.params.date ? this.$route.params.date : '2023-05-23';

    this.floodDate = date;

    const options = {
      key: 'A3BPUowjKKop9JefvkWKcoZGfb4tahaE',
      verbose: true,
      lat: lat ? lat : 41.902782,
      lon: long ? long : 12.496366,
      zoom: 5,
    };

    window.windyInit(options, windyAPI => {
          // windyAPI is ready, and contain 'map', 'store',
          // 'picker' and other usefull stuff
          // console.log(windyAPI)
          // const { map } = windyAPI;
          // .map is instance of Leaflet map
      console.log(windyAPI)
          // window.L.popup()
          //     .setLatLng([50.4, 14.3])
          //     .setContent('Hello World')
          //     .openOn(map);
      });

    this.map = window.L.map('flood', {
        center: [ lat ? lat : 41.893973, long ? long : 12.509219],
        zoom: 6,
    });

    const map = this.map;


    window.L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
    }).addTo(map);

    // let map = null;

    // window.windyInit(options, windyAPI => {
    //     // windyAPI is ready, and contain 'map', 'store',
    //     // 'picker' and other usefull stuff

    //     const { map } = windyAPI;

        // .map is instance of Leaflet map

        let marker = window.L.marker([lat ? lat : 44.566755, long ? long : 11.756816], {icon: defaultIcon}).addTo(map);
        // window.L.popup()
        //     .setLatLng([41.902782, 12.496366])
        //     .openOn(map);


        


        const context = this;

        map.on('click', function(e) {
        if (marker !== null) {
          map.removeLayer(marker);
        }
        marker = window.L.marker([e.latlng.lat, e.latlng.lng], {icon: defaultIcon}).addTo(map);
        console.log(e.latlng.lat, e.latlng.lng);
        this.lat = e.latlng.lat;
        this.long = e.latlng.lng;

        axios.post('https://api.windy.com/api/point-forecast/v2', {
          lat: e.latlng.lat,
          lon: e.latlng.lng,
          model: "gfs",
          parameters: ["wind", "temp", "precip", "rh", "pressure", "ptype", "dewpoint", "windGust", "cape"],
          levels: ["surface"],
          key: "BqHOSMKDLb7H9wGqMCpFDOG5NCaS2vex"
      }).then(res => {
          context.firstRisk = context.getFirstRiskRandomInt();
          context.secondRisk = context.getSecondRiskRandomInt();
          context.thirdRisk = context.getThirdRiskRandomInt();

          if(e.latlng.lat ==  44.566755 && e.latlng.lng == 11.756816){
            context.firstRisk = 40;
            context.secondRisk = 30;
            context.thirdRisk = 20;
          }
          context.windGust = res.data['gust-surface'][0].toFixed(8);
          context.convectiveEnergy = res.data['cape-surface'][0].toFixed(8);
          context.potentialEnergy.data = res.data['cape-surface'];
          context.precip = res.data['past3hprecip-surface'][2].toFixed(8);
          context.precipDataset.data=res.data['past3hprecip-surface'];
          context.windSpeedU = res.data['wind_u-surface'][0].toFixed(2);
          context.winduDataset.data = res.data['wind_u-surface'];
          context.windSpeedV = res.data['wind_v-surface'][0].toFixed(2);
          context.windvDataset.data = res.data['wind_v-surface'];
          context.umidity = res.data['rh-surface'][0].toFixed(2);
          context.airTemperature = context.toCelsius(res.data['temp-surface'][0]).toFixed(2);
          let celsiusTempArray = [];
          res.data['temp-surface'].forEach(element => {
            celsiusTempArray.push(context.toCelsius(element));
          });
          context.tempDataset.data = celsiusTempArray;

          context.pressure = res.data['pressure-surface'][0].toFixed(2);
          context.airPressureDataset.data = res.data['pressure-surface'];
          context.ptype = context.getPType(res.data['ptype-surface'][0]);
          context.dewPoint = context.toCelsius(res.data['dewpoint-surface'][0]).toFixed(2);
          let celsiusDewPointArray = [];
          res.data['dewpoint-surface'].forEach(element => {
            celsiusDewPointArray.push(context.toCelsius(element));
          });
          context.dewPointDataset.data = celsiusDewPointArray;

          let dates = []

          res.data['ts'].forEach(element => {
            dates.push(context.getFormattedDate(element));
          });
          context.xAsisLabels = dates;
      });

    });

    // });

    axios.post('https://api.windy.com/api/point-forecast/v2', {
        lat: lat ? lat : 44.566755,
        lon: long ? long : 11.756816,
        model: "gfs",
        parameters: ["wind", "temp", "precip", "rh", "pressure", "ptype", "dewpoint", "windGust", "cape"],
        levels: ["surface"],
        key: "BqHOSMKDLb7H9wGqMCpFDOG5NCaS2vex"
    }).then(res => {
      console.log(res);
      this.firstRisk = 40;
      this.secondRisk = 30;
      this.thirdRisk = 20;
      this.windGust = res.data['gust-surface'][0].toFixed(8);
      this.windGustDataset.data = res.data['gust-surface'];
      this.convectiveEnergy = res.data['cape-surface'][0].toFixed(8);
      this.potentialEnergy.data = res.data['cape-surface'];
      this.precip = res.data['past3hprecip-surface'][2].toFixed(8);
      this.precipDataset.data=res.data['past3hprecip-surface'];
      this.windSpeedU = res.data['wind_u-surface'][0].toFixed(2);
      this.winduDataset.data = res.data['wind_u-surface'];
      this.windSpeedV = res.data['wind_v-surface'][0].toFixed(2);
      this.windvDataset.data = res.data['wind_v-surface'];
      this.umidity = res.data['rh-surface'][0].toFixed(2);
      this.airTemperature = this.toCelsius(res.data['temp-surface'][0]).toFixed(2);
      let celsiusTempArray = [];
      res.data['temp-surface'].forEach(element => {
        celsiusTempArray.push(this.toCelsius(element));
      });
      this.tempDataset.data = celsiusTempArray;

      this.pressure = res.data['pressure-surface'][0].toFixed(2);
      this.airPressureDataset.data = res.data['pressure-surface'];
      this.ptype = this.getPType(res.data['ptype-surface'][0]);
      this.dewPoint = this.toCelsius(res.data['dewpoint-surface'][0]).toFixed(2);
      let celsiusDewPointArray = [];
      res.data['dewpoint-surface'].forEach(element => {
        celsiusDewPointArray.push(this.toCelsius(element));
      });
      this.dewPointDataset.data = celsiusDewPointArray;

      let dates = []

      res.data['ts'].forEach(element => {
        dates.push(this.getFormattedDate(element));
      });
      this.xAsisLabels = dates;
      console.log(this.xAsisLabels);

    });


    // const context = this;


    // let marker = map.marker([44.566755, 11.756816]).addTo(map);

    // map.on('click', function(e) {
    //   // if (marker !== null) {
    //   //   // map.removeLayer(marker);
    //   // }
      

    //   axios.post('https://api.windy.com/api/point-forecast/v2', {
    //     lat: e.latlng.lat,
    //     lon: e.latlng.lng,
    //     model: "gfs",
    //     parameters: ["wind", "temp", "precip", "rh", "pressure", "ptype", "dewpoint"],
    //     levels: ["surface"],
    //     key: "BqHOSMKDLb7H9wGqMCpFDOG5NCaS2vex"
    // }).then(res => {
    //     context.firstRisk = context.getRandomInt();
    //     context.secondRisk = context.getRandomInt();
    //     context.thirdRisk = context.getRandomInt();
    //     context.precipDataset.data = null;

    //     context.precip = res.data['past3hprecip-surface'][2].toFixed(8);
    //     context.precipDataset.data=res.data['past3hprecip-surface'];
    //     context.windSpeedU = res.data['wind_u-surface'][0].toFixed(2);
    //     context.winduDataset.data = res.data['wind_u-surface'];
    //     context.windSpeedV = res.data['wind_v-surface'][0].toFixed(2);
    //     context.windvDataset.data = res.data['wind_v-surface'];
    //     context.umidity = res.data['rh-surface'][0].toFixed(2);
    //     context.umidityDataset.data = res.data['rh-surface'];
    //     context.airTemperature = context.toCelsius(res.data['temp-surface'][0]).toFixed(2);
    //     let celsiusTempArray = [];
    //     res.data['temp-surface'].forEach(element => {
    //       celsiusTempArray.push(context.toCelsius(element));
    //     });
    //     context.tempDataset.data = celsiusTempArray;
    //     context.pressure = res.data['pressure-surface'][0].toFixed(2);
    //     context.airPressureDataset.data = res.data['pressure-surface'];
    //     context.ptype = context.getPType(res.data['ptype-surface'][0]);
    //     context.dewPoint = context.toCelsius(res.data['dewpoint-surface'][0]).toFixed(2);
    // });

    // });

  },
  destroyed() {
    console.log('DESTROYED');
    this.map.invalidateSize();
    this.map = null;
  },
  components: {
    LineChart,
    BarChart
  },
  methods: {
    changeFloodDate(){
      this.$router.push(`/rischio-alluvioni/${this.lat}/${this.long}/${this.floodDate}`);
      this.$router.go(0);
    //   const lat = this.$route.params.lat;
    // const long = this.$route.params.long;
    //   const url = 'https://ows.globalfloods.eu/glofas-ows/ows.py?time=' + this.floodDate;
    //   console.log(url);
    //   this.map.remove();

    //   this.map = window.L.map('flood', {
    //     center: [ lat ? lat : 41.893973, long ? long : 12.509219],
    //     zoom: 6,
    //   });

    //   let map = this.map;

    //   window.L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
    //   }).addTo(map);

    //   this.wmsLayer = window.L.tileLayer.wms(url , {
    //     layers: "RapidImpactAssessment",
    //     format: 'image/png',
    //     opacity: 0.7,
    //     transparent: true
    //   }).addTo(map);

    //   this.wmsLayer2 = window.L.tileLayer.wms(url, {
    //     layers: "RapidFloodMapping",
    //   format: 'image/png',
    //   opacity: 0.7,
    //   transparent: true
    // }).addTo(map);
    
    },
    getColor(risk){
        if(risk <= 40){
          return 'green';
        }else if(risk > 40 && risk < 70){
          return 'orange';
        }else {
          return 'red';
        }
      },
      toCelsius(kelvin) {
        if(Number.isFinite(kelvin)) { // Checking if kelvin is a number.
            const KELVIN_CELSIUS_DIFF = 273.15; // maybe unnecessary here, but it is good practice to avoid magic numbers.
            let celsius = kelvin - KELVIN_CELSIUS_DIFF;
            return celsius; // could also be just return kelvin - KELVIN_CELSIUS_DIFF;
        } else {
            // kelvin is not a number, should be handled.
        }
      },
      getPType(ptype){
        switch(ptype){
          case 0:
            return 'Nessuna precipitazione';
          case 1:
            return 'Pioggia';
          case 3:
            return 'Grandine';
          case 5:
            return 'Neve';
          case 8:
            return 'Pioggia Gelata'
        }
      },
      getRandomInt(){
        const min = 5;
        const max = 90;
        return Math.random() * (max - min) + min;
      },
      getFirstRiskRandomInt(){
        const min = 30;
        const max = 39;
        return Math.random() * (max - min) + min;
      },
      getSecondRiskRandomInt(){
        const min = 20;
        const max = 30;
        return Math.random() * (max - min) + min;
      },
      getThirdRiskRandomInt(){
        const min = 10;
        const max = 29;
        return Math.random() * (max - min) + min;
      },
      getFormattedDate(ts){
        const date = new Date(ts);
        const dateFormat = (date.getDate()<10?'0':'') + date.getDate() + "/"+ (date.getMonth()<10?'0':'') + (date.getMonth()+1) + ' ' + date.getHours() + ":" + (date.getMinutes()<10?'0':'') + date.getMinutes();
        // const dateFormat =date.getHours();
        return dateFormat;
      }
    
    
  }
};
</script>

<style>
.red>div>.fill, .red>div>.bar{
  border-color: red !important;
}
.c100.red:hover > span {
  color: red !important;
}
.map-frame {
  height: 55vh;
  width: 100%;
/*   We use outline over border as has issues in some cases */
  /* outline: 1px solid black; */
}
#flood{
  height: 100%;
}
#windy{
  height: 55vh;
}
.basis-0{
  flex-grow: 0;
}
#windy #map_container .leaflet-control-container {
    display: block !important;
}
#windy #bottom *,
#windy #logo-wrapper *,
#windy #plugins *,
#windy #plugins,
#windy #mobile-ovr-select,
#windy #embed-zoom,
#windy #windy-app-promo * {
z-index: 1000;
}
#windy #bottom #progress-bar {
background-color: transparent;
text-align: left;
}
#logo-wrapper{
  display: none;
}
#progress-bar{
  display: none !important;
}
</style>

